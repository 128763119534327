/* ========== Universal Stylings ========== */
.bo-app
{
    margin: 0;
    color:#fff;
    background: #fff;
}
.bo-app .logo
{
    padding: 2em;
    max-width: 300px;
    width: 100%;
}
.bo-app p
{
    font-size: 1.2em;
}
.bo-app h1
{
    position: relative;
}
.bo-app section:nth-child(1)
{
/*  To set children position to absolute, we set parent to relative positiion
    Flex direction = column | align items center  */
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 400px;
    padding: 5vh 20vw 5vh ;
}
.bo-app .fas.fa-arrow-circle-down
{

  font-size: 3em;
  color: #ffb950;
}
@media (max-width: 991px){
    .bo-app .fas.fa-arrow-circle-down
    {    
      font-size: 2em;

    }
    .bo-app section
   {
    padding: 12vh 12vw !important;
   }
   
  }

.bo-app section
{
/*  To set children position to absolute, we set parent to relative positiion
    Flex direction = column | align items center  */
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 400px;
    padding: 40px 20vw ;
}

/* ========== Background colors of each section ========== */

.bo-app .dark
{
    background: #202731;
}
.bo-app .purple
{
    background: purple;
}

.bo-app .orange
{
    background: orange;
}

/* ========== WAVES ========== */
/* ---------- WAVE BOTTOM -------- */
.bo-app .wave-bottom 
{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}
.bo-app .wave-bottom svg , .bo-app .wave-bottom svg
{
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 140px;
}
.bo-app  .shape-fill.orange 
{
    fill:  orange;
}
.bo-app  .shape-fill.purple 
{
    fill:  purple;
}
/* ---------- WAVE TOP -------- */
.bo-app  .wave-top 
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.bo-app .wave-top svg 
{
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 141px;
    transform: rotateY(180deg);
}