@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');
/* FONTS */

*
{
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1.2px;
}
#sign
{
  stroke-dasharray: 500;
  stroke-dashoffset: 500;
  animation: sign 4s ease;
  animation-fill-mode: forwards;
}
@keyframes sign {
  to {
    stroke-dashoffset: 0;
  }
}
/* ========== Universal Stylings ========== */
/* CENTER ITEMS */
.item-center
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
/* ROUND */

.round
{
  border-radius: 1em;
}

/* BUTTON */
.btn-white
{
  text-decoration: none;
  border-radius: 2em;
  background: #eee;
  padding: 0.5em 0.7em 0.5em;  
  color: #222;
  text-transform: uppercase;
}
.btn-white .fas.fa-arrow-circle-right
{
  color: purple;
}
.btn-white .fas.fa-arrow-circle-right.orange
{
  color: orange;
}
/* ICONS */

.icons-center
{
  text-align: center;

}
.icons-center i
{
  font-size: 2em;
  padding: 0.5em;
  color: #73266E;

}
/* -- TEXT */
.text-banner
{
  margin: 0 20vw;
}
@media (max-width: 991px){
  .text-banner
  {
    margin: 0 10vw;
  }
}



.text-large span
{
  font-size: 1.3em;
  letter-spacing: 5px;
  display: block;
  text-transform: uppercase;
  text-align: center;
}
/* ======= COLORS ======== */
/* -- Background colors -- */
.bg-purple
{
	background: rgb(90, 0, 102);	
}
.bg-orange
{
	background: orange;	
}
.bg-dark-grey
{
  background: #202731; 
}
/* -- Font colors --- */
.text-purple
{
    color: rgb(90, 0, 102);	
}
 .text-light-pink
{
  color:#F29472;
}
.text-green
{
  color: green;
}

 .text-orange
{
  color: #ffb950;
}
.text-fire
{
  color: #F2622E;
}
.text-red
{
  color: red;
}

h2
{
	font-weight: 700;
	font-size: 2.5rem;
}

/* === NAVBAR === */
.navbar-brand 
{
  font-size: 1em;
  color: rgb(97, 0, 97) !important;
}
.navbar .logo
{
  width: 4em;
}
@media (max-width: 400px){ 
 .navbar-brand 
 {
   letter-spacing: 0px;
   width: 2em;  
 }
 .navbar-brand .logo
 {
  width: 3em;
 }
}

.quote{

  padding: 0 20vw;
  font-size: 1.1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  text-align: center;
  line-height: 1.5em;
}
.quote-i{
  color: #ffb950;
  font-size: 1.5em;
}


/* === HOME === */
/* --- HOME BANNER ----*/
.home 
{
  width: 100%;
  overflow-x: hidden;
}
.home .home-banner
{
  background: linear-gradient(0deg,  #F2622E, #ffb950);
}
.home .home-banner .subt
{
  color: #73266E;
  text-transform: uppercase; 
}
.home .home-banner .logo
{
  width: 100%;
  max-width: 600px;
  border-radius: 5em;
}
.app-image
{
  max-width: 500px;
  width: 100%;
  padding: 2em;
}
@media (max-width: 991px){
  .home .home-banner .logo
{ 
  border-radius: 3em;
}
  
}
.home .home-banner .fas.fa-angle-right
{
  margin-right: 0.5em;
  top:2px;
  color: rgba(134, 0, 151, 0.428);
}
.home .home-banner h2
{
  font-weight:500;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  padding-bottom: 0.5em;
  color: #73266E;
}
.home .home-banner p
{
  font-size: 1.2em;
  letter-spacing: 2px;
  color:#fff;
  font-weight: 500;
}
.home .home-banner .text-pl
{
  padding: 0 10vw;
}

.home .home-banner .row
{
  margin: 0 10%;
  align-items: center; 
}


.home .home-banner .fa.fa-angle-double-right,  .home .home-banner .fas.fa-fire
{
  margin-right: 0.6em;
  color: #73266E;
  position: relative; 
}
/* HOME ELEMENT-ONE*/

.home .element-one
{
  background: #fff;  
}
.home .element-one .row
{
  margin: 0 5%; 
}

.home .element-one .text-box
{
  padding: 1em;
}
.home .element-one .text-box h2
{
  padding-bottom: 0.5em;
  position: relative;
}
.home .element-one .fa.fa-angle-double-right
{
  font-size: 0.6em;
  margin-right: 0.6em;
  color: #73266E;
  position: relative;
  top: -2px;
}
.home .element-one .text
{
  margin: 0.4em 0.2em;
  padding: 0.2em 0;
  line-height: 1.3em;
  font-size: 1.3em;
  letter-spacing: 2px;

}
#info-p{
  font-size: 13;
  color: #202731;
  font-style: italic;
}

/* -- INFO SECTION */
/* ========== Universal Stylings ========== */
.head-box h2, .head-box h3, .head-box h4 , .head-box h5, .head-box h6
{
	position: relative;  
}
.info-section .img-fluid
{

	
	max-width: 650px;
  width: 100%;
  padding: 2em;
}
.info-section i.box-circle-solid{
	background-color:orange;
	border-radius: 50%;
  color: #fff;
  font-size: 22px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  width: 55px;
}
.info-section .text-underline-rb-orange:before{
	content:"";
	position: absolute;
	width:100px;
	height: 4px;
	left:0;
	background:orange;
	bottom: -15px;
	border-radius: 10px;
}
 .text-underline-primary:before{
	content:"";
	position: absolute;
	width:50px;
	height: 4px;
	left:50%;
	margin-left:-25px;
	background:orange ;
	bottom: -10px;
	border-radius: 10px;
}
.text-underline-purple:before{
	content:"";
	position: absolute;
	width:50px;
	height: 4px;
	left:50%;
	margin-left:-25px;
	background:purple ;
	bottom: -10px;
	border-radius: 10px;
}
.info-section .text-underline-rb-primary:before{
	content:"";
	position: absolute;
	width:100px;
	height: 4px;
	left:0;
	background:orange ;
	bottom: -15px;
	border-radius: 10px;
}

/* ========== Info Section ========== */
.info-section
{
	float: left;
	width:100%;	
	z-index: 1;
  padding-left: 20%;
  padding-right: 20%;
  padding-bottom: 15px;
}

.info-section h3
{
	font-size: 1.4rem;
  
}
.info-section p 
{
    font-size: 1rem;
    line-height: 1.3rem;
    padding: 8px;
}
.info-section .box h2
{
    font-size:24px;
    margin-bottom:20px;
    margin-top:0;
}
.info-section .box i
{
    font-size:20px;
}
.info-section .box
{
    display:flex;
}
.info-section .text-box
{
    flex:1 1 0;
    text-align:left;
}
.info-section .icon-box
{
    line-height: 1.2;
    width:70px;
}
.content-half
{
    color:#fff;
}
.content-half ul
{
    padding:0;
    list-style:none;
    margin: 0 10%;
    justify-content: center;


}
.content-half ul li
{
    margin:15px 0;
    float:left;
    width:100%; 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.content-half ul li i
{
  float:left;
  font-size:2em;
  padding-top:10px;
  padding-bottom:10px; 
  color: #f29472c7;
}
.content-half ul li .list-content
{
    float:left;
    width: 90%;
    padding-left: 2em;
  
}
.content-half ul li strong
{
  font-size:18px;
  letter-spacing: 2px;
  color: #ffb950;
}

/*--- Three Block Panel ---*/
.head-box
{
	padding: 1em;
  margin-left: 10%;
  margin-right: 10%;
}

.three-panel-block
{
	float: left;
	width: 100%;
}
.three-panel-block i
{
	font-size: 1.5rem;
	margin-bottom: 15px;
}
.three-panel-block i.box-round
{
	border-color: inherit;
	border-width: 1px;
	border-style:solid;
	padding:16px;
	border-radius: 50%;
}
.p-small{
  font-size: 1.1em !important;
}

.home-ul{
  padding-left: 10vw;
  color: white;
  font-size: 1.2em;
}
.home-ul li{
  color: purple;
}


/* ========== Responsive Stylings ========== */
@media (max-width: 991px){
	.container
  {
		width: 100%;
	}
  .head-box
{
	padding: 1em;
  margin-left: 5%;
  margin-right: 5%;
}
  .home .home-banner .text-pl
  {
    padding: 0 5vw;
  }
	
	.content-half ul li .list-content
  {
		width:80%;
	}
	.content-half ul li strong
  {
		line-height: 0;
	}
}
@media (max-width: 767px){
	.container
  {
		width:100%;
	}	
  .max-w-p-small
  {
  padding: 0 !important;
  }
  .info-section
  {   
   padding:0;
  }
}

/*== CHATBOT - BLOB BACKGROUND == */
.text-underline-white:before{
	content:"";
	position: absolute;
	width:50px;
	height: 4px;
	left:50%;
	margin-left:-25px;
	background:#fff ;
	bottom: -15px;
	border-radius: 10px;
}

.blob
{
    aspect-ratio: 900/300;
    background-repeat: no-repeat;   
    background-size: cover;
    background-image: url('./blob-orange.svg');    
}
.blob.two
{          
  background-image: url('./blob-orange-flip.svg');
}
.blob.two iframe
{
  background:#202731 ;
}

@media (min-width: 991px){
  .blob.two iframe
{
  width: 60%;

}
.blob-content p 
{max-width: 50%;

};
}

.blob-content
{
  padding: 7vh 10vw 5vh;
  color: #fff;
  font-size: 1.5em; 
  letter-spacing: 2px;
}
.blob-content h3
{
  position: relative;
}

.blob-content .fas.fa-arrow-circle-down
{

  font-size: 1.5em;
  color: #ffb950;
}


/* ====== WAVES ====== */
/* ====== WAVE TOP ====== */
.wave-top 
{
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}
.wave-top.flip
{  
  transform: rotate(0deg);
}
.wave-top svg 
{
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 140px;
}
.wave-top .shape-fill.orange
{
  fill: #F2622E;
}
.wave-top .shape-fill.purple 
{
  fill: rgb(90, 0, 102);	
}

/* ==== WAVES-TOP ==== */
.waves-top {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}
.waves-top svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 100px;
}
.waves-top .shape-fill {
  fill: #ffc876;
}

/* === WAVE BOTTOM ==== */
.wave-bottom 
{
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}
.wave-bottom.flip
{  
  transform: rotate(180deg);
}
.wave-bottom svg 
{
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 180px;
}
.wave-bottom .shape-fill 
{
  fill: rgb(90, 0, 102);	
}
.wave-bottom .shape-fill.orange
{
  fill: orange;	
}

/* Footer */
footer.nb-footer {
background: #001220;
/*border-top: 4px solid #b78c33;*/ }
footer.nb-footer .about {
margin: 0 auto;
max-width: 1170px;
text-align: center; }
footer.nb-footer .about p {
font-size: 14px;
color: #999;
margin-top: 10px; }
footer.nb-footer .about .social-media {
margin-top: 8px; }

footer.nb-footer .about .social-media .list-inline{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
footer.nb-footer .about .social-media ul li{
  margin-right: 0.15em;
}
footer.nb-footer .about .social-media ul li a {
display: inline-block;
width: 45px;
height: 45px;
line-height: 45px;
border-radius: 50%;
font-size: 16px;
color: #b78c33;
border: 1px solid rgba(255, 255, 255, 0.3); 
}
footer.nb-footer .about .social-media ul li a:hover {
background: #b78c33;
color: #fff;
border-color: #b78c33; 
}
footer.nb-footer .footer-info-single {
margin-top: 30px; 
}
footer.nb-footer .footer-info-single .title {
color: #aaa;
text-transform: uppercase;
font-size: 16px;
border-left: 4px solid #b78c33;
padding-left: 5px; 
}
footer.nb-footer .footer-info-single ul li a { 
display: block;
text-decoration-color: rgba(153, 153, 153, 0.685);
color: #aaa;
padding: 2px 0; 
}
footer.nb-footer .footer-info-single ul li a:hover {
color: #b78c33; 
}
footer.nb-footer .footer-info-single p {
  font-size: 13px;
  line-height: 20px;
  color: #aaa;
}
footer.nb-footer .copyright 
{
  background: #001627;
  padding: 7px 0;
  color: #999;
}
footer.nb-footer .copyright p {
margin: 0;
padding: 0; 
font-size: 0.7em;
}

.max-img-w{
  max-height: 200px;
  height: 100%;
}
.max-w-p-small
{
padding: 0 10vw;
line-height: 1.5em;
font-size: 1.2em;
}

.border-sm-black{
  border-radius: 2em;
  border: 1px solid grey;
  padding: 1em ;
}

.fas.fa-info-circle{
  font-size: 1.5em;
  padding-right: 0.4em;
}
.fas.fa-exclamation-triangle
{
  font-size: 0.8em;
  padding-right: 0.4em;
  position: relative;top: -2px;
}
